<template>
  <div class="content">
    <!-- 顶部导航开始 -->
    <div class="top">
      <!-- 顶部导航图片开始 -->
      <img src="../assets/img/schedule-top-img.png" alt="" />
      <!-- 顶部导航图片结束 -->
      <!-- 内容容器开始 -->
      <div class="container top-nav">
        <!-- 顶部导航开始 -->
        <my-header class="hidden-xs-only" active="3"></my-header>
        <language-change2
          class="hidden-sm-and-up"
          active="3"
        ></language-change2>
        <!-- 顶部导航结束 -->
      </div>
      <!-- 内容容器结束 -->
    </div>
    <!-- 顶部导航结束 -->
    <!-- 内容开始 -->
    <div class="middle">
      <div class="container">
        <!-- 标题部分开始 -->
        <div class="title">
          {{ $t("message.listOfExhibition") }}
          <span class="small"></span>
          <span class="big"></span>
        </div>
        <!-- 标题部分结束 -->
        <!-- 年|月切换开始 -->
        <div class="schedule-month-select">
          <!-- 年份tab开始 -->
          <div class="schedule-tab">
            <div
              class="schedule-tab-item"
              :class="tab == 0 ? 'schedule-tab-active' : ''"
              @click="changeTab(0)"
            >
              {{ year }}
            </div>
            <div
              class="schedule-tab-item"
              :class="tab == 1 ? 'schedule-tab-active' : ''"
              @click="changeTab(1)"
            >
              {{ year - 1 }}
            </div>
            <div
              class="schedule-tab-item"
              :class="tab == 2 ? 'schedule-tab-active' : ''"
              @click="changeTab(2)"
            >
              {{ year - 2 }}
            </div>
          </div>
          <!-- 年份tab结束 -->
          <!-- 年|月开始 -->
          <div class="schedule-month hidden-xs-only">
            <div
              class="schedule-month-item"
              :class="monthChecked ? 'schedule-month-active' : ''"
              @click="changeMonthSelect(1)"
            >
              {{ $t("message.byMonth") }}
            </div>
            <div
              class="schedule-month-item"
              :class="!monthChecked ? 'schedule-month-active' : ''"
              @click="changeMonthSelect(2)"
            >
              {{ $t("message.byYear") }}
            </div>
          </div>
          <!-- 年|月结束 -->
        </div>
        <!-- 年|月切换结束 -->

        <!-- 月份tab开始 -->
        <div class="month-tab" v-show="monthChecked">
          <!-- 线开始 -->
          <div class="month-tab-hr"></div>
          <!-- 线结束 -->
          <!-- 内容开始 -->
          <div class="month-content">
            <!-- 左侧图标开始 -->
            <div class="month-content-img" @click="changeMonth('-')">
              <img src="../assets/svg/schedule-left-icon.svg" alt="" />
            </div>
            <!-- 左侧图标结束 -->
            <!-- 单一月份开始 -->
            <div class="flex">
              <div
                class="month-content-item"
                :class="month == 1 ? 'month-content-active' : ''"
                @click="changeMonth(1)"
              >
                <div v-show="language == 'cn'">1月</div>
                <div v-show="language == 'en'">Jay</div>
                <div class="month-content-round"></div>
              </div>
              <div
                class="month-content-item"
                :class="month == 2 ? 'month-content-active' : ''"
                @click="changeMonth(2)"
              >
                <div v-show="language == 'cn'">2月</div>
                <div v-show="language == 'en'">Feb</div>
                <div class="month-content-round"></div>
              </div>
              <div
                class="month-content-item"
                :class="month == 3 ? 'month-content-active' : ''"
                @click="changeMonth(3)"
              >
                <div v-show="language == 'cn'">3月</div>
                <div v-show="language == 'en'">Mar</div>
                <div class="month-content-round"></div>
              </div>
              <div
                class="month-content-item"
                :class="month == 4 ? 'month-content-active' : ''"
                @click="changeMonth(4)"
              >
                <div v-show="language == 'cn'">4月</div>
                <div v-show="language == 'en'">Apr</div>
                <div class="month-content-round"></div>
              </div>
              <div
                class="month-content-item"
                :class="month == 5 ? 'month-content-active' : ''"
                @click="changeMonth(5)"
              >
                <div v-show="language == 'cn'">5月</div>
                <div v-show="language == 'en'">May</div>
                <div class="month-content-round"></div>
              </div>
              <div
                class="month-content-item"
                :class="month == 6 ? 'month-content-active' : ''"
                @click="changeMonth(6)"
              >
                <div v-show="language == 'cn'">6月</div>
                <div v-show="language == 'en'">Jun</div>
                <div class="month-content-round"></div>
              </div>
              <div
                class="month-content-item"
                :class="month == 7 ? 'month-content-active' : ''"
                @click="changeMonth(7)"
              >
                <div v-show="language == 'cn'">7月</div>
                <div v-show="language == 'en'">Jul</div>
                <div class="month-content-round"></div>
              </div>
              <div
                class="month-content-item"
                :class="month == 8 ? 'month-content-active' : ''"
                @click="changeMonth(8)"
              >
                <div v-show="language == 'cn'">8月</div>
                <div v-show="language == 'en'">Aug</div>
                <div class="month-content-round"></div>
              </div>
              <div
                class="month-content-item"
                :class="month == 9 ? 'month-content-active' : ''"
                @click="changeMonth(9)"
              >
                <div v-show="language == 'cn'">9月</div>
                <div v-show="language == 'en'">Sep</div>
                <div class="month-content-round"></div>
              </div>
              <div
                class="month-content-item"
                :class="month == 10 ? 'month-content-active' : ''"
                @click="changeMonth(10)"
              >
                <div v-show="language == 'cn'">10月</div>
                <div v-show="language == 'en'">Oct</div>
                <div class="month-content-round"></div>
              </div>
              <div
                class="month-content-item"
                :class="month == 11 ? 'month-content-active' : ''"
                @click="changeMonth(11)"
              >
                <div v-show="language == 'cn'">11月</div>
                <div v-show="language == 'en'">Nov</div>
                <div class="month-content-round"></div>
              </div>
              <div
                class="month-content-item"
                :class="month == 12 ? 'month-content-active' : ''"
                @click="changeMonth(12)"
              >
                <div v-show="language == 'cn'">12月</div>
                <div v-show="language == 'en'">Dec</div>
                <div class="month-content-round"></div>
              </div>
            </div>
            <!-- 单一月份结束 -->
            <!-- 右侧图标开始 -->
            <div class="month-content-img" @click="changeMonth('+')">
              <img src="../assets/svg/schedule-right-icon.svg" alt="" />
            </div>
            <!-- 右侧图标结束 -->
          </div>
          <!-- 内容结束 -->
        </div>
        <!-- 月份tab结束 -->
        <!-- 内容开始 -->
        <div class="middle-content hidden-xs-only">
          <el-row :gutter="30" v-if="lists.length != 0">
            <!-- 单一内容开始 -->
            <el-col
              class="mb-30"
              :sm="8"
              v-for="(item, index) in lists"
              :key="index"
            >
              <div class="middle-item" @click="handleDetail(item.id)">
                <!-- 时间内容开始 -->
                <div class="middle-item-content">
                  <!-- 时间开始 -->
                  <div class="middle-item-time">
                    {{ item.startTime | filterTime }}
                    <span>-</span>
                    {{ item.endTime | filterTime }}
                  </div>
                  <div class="middle-item-triangle"></div>
                  <!-- 时间结束 -->
                </div>
                <!-- 时间内容结束 -->
                <!-- 标题开始 -->
                <div class="middle-item-title">
                  <div v-show="language == 'cn'">{{ item.name }}</div>
                  <div v-show="language == 'en'">{{ item.enName }}</div>
                </div>
                <!-- 标题结束 -->
                <!-- 位置开始 -->
                <div class="middle-item-address">
                  <div v-show="language == 'cn'">{{ item.expo }}</div>
                  <div v-show="language == 'en'">{{ item.enExpo }}</div>
                </div>
                <!-- 位置结束 -->
              </div>
            </el-col>
            <!-- 单一内容结束 -->
          </el-row>
        </div>
        <div class="middle-content hidden-sm-and-up">
          <el-row :gutter="10" v-if="lists.length != 0">
            <!-- 单一内容开始 -->
            <el-col
              class="mb-16"
              :span="12"
              v-for="(item, index) in lists"
              :key="index"
            >
              <div class="middle-item-sm" @click="handleDetail(item.id)">
                <!-- 内容开始 -->
                <div class="middle-item-content-sm">
                  <!-- 时间开始 -->
                  <div class="middle-item-time-sm">
                    {{ item.startTime | filterTime }}~{{
                      item.endTime | filterTime
                    }}
                  </div>
                  <!-- 时间结束 -->
                  <!-- 标题开始 -->
                  <div class="middle-item-title-sm">
                    <div v-show="language == 'cn'">{{ item.name }}</div>
                    <div v-show="language == 'en'">{{ item.enName }}</div>
                  </div>
                  <!-- 标题结束 -->
                  <!-- 位置开始 -->
                  <div class="middle-item-address-sm">
                    <div v-show="language == 'cn'">{{ item.expo }}</div>
                    <div v-show="language == 'en'">{{ item.enExpo }}</div>
                  </div>
                  <!-- 位置结束 -->
                </div>
                <!-- 内容结束 -->
              </div>
            </el-col>
            <!-- 单一内容结束 -->
          </el-row>
        </div>
        <!-- 内容结束 -->
      </div>
    </div>
    <!-- 内容结束 -->
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      year: "", //当前年份
      month: "", //当前月份
      tab: 0,
      lists: [], //数据
      width: "", //每个月份的宽度
      marginLeft: 0,
      monthChecked: false,
    };
  },
  mounted() {
    const date = new Date();
    this.year = date.getFullYear(); //当前年份
    this.month = date.getMonth() + 1; //当前月份
    this.getLists();
  },
  methods: {
    // 获取数据
    getLists() {
      let year = this.year;
      if (this.tab == 1) {
        year -= 1;
      } else if (this.tab == 2) {
        year -= 2;
      }
      if (this.monthChecked) {
        this.$axios
          .get(`api/expoList?state=1&year=${year}&month=${this.month}`, {})
          .then((res) => {
            if (res.data.code == 0) {
              this.lists = res.data.data;
            }
          });
      } else {
        this.$axios
          .get(`api/expoList?state=1&year=${year}&month=`, {})
          .then((res) => {
            if (res.data.code == 0) {
              this.lists = res.data.data;
            }
          });
      }
    },
    // 月份选择事件
    changeMonthSelect(value) {
      if (value == 1) {
        this.monthChecked = true;
      } else if (value == 2) {
        this.monthChecked = false;
      }
      this.getLists();
    },
    // tab切换事件
    changeTab(value) {
      this.tab = value;
      this.getLists();
    },
    // month切换事件
    changeMonth(value) {
      if (value == "+") {
        if (this.month < 12) {
          this.month += 1;
        }
      } else if (value == "-") {
        if (this.month > 1) {
          this.month -= 1;
        }
      } else {
        this.month = value;
      }
      this.getLists();
    },
    // 查看详情
    handleDetail(value) {
      this.$router.push({ path: "/scheduleDetail", query: { id: value } });
    },
  },
  filters: {
    filterTime: function (value) {
      let date = new Date(value);
      let month = date.getMonth() + 1; //当前月份
      let day = date.getDate();
      return `${month}/${day}`;
    },
  },
  computed: {
    ...mapState(["language"]),
  },
};
</script>

<style scoped>
.schedule-month-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.schedule-tab {
  margin-top: 20px;
  display: flex;
}

.schedule-tab-item {
  color: #333333;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 24px;
  cursor: pointer;
  margin-right: 10px;
  padding: 7px 20px;
}

.schedule-month {
  margin-top: 40px;
  display: flex;
  border-bottom: 1px solid #dddddd;
}

.schedule-month-item {
  width: 100px;
  height: 40px;
  text-align: center;
  box-sizing: border-box;
  line-height: 40px;
  cursor: pointer;
  margin-bottom: -1px;
}

.schedule-month-active {
  color: #004bc7;
  border-bottom: 2px solid #004bc7;
}

@media (min-width: 768px) {
  .schedule-tab {
    margin-top: 40px;
    display: flex;
  }

  .schedule-tab-item {
    color: #333333;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 27px;
    cursor: pointer;
    margin-right: 10px;
    padding: 7px 20px;
  }
}

.schedule-tab-active {
  color: #ffffff;
  background-color: #004bc7;
}

.month-tab {
  margin-top: 40px;
  padding: 44px 0 20px;
  position: relative;
}

.month-tab-hr {
  height: 1px;
  background-color: #dddddd;
}

.month-content {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.month-content-img {
  cursor: pointer;
}

.month-content-item {
  width: 93px;
  color: #666666;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  flex-flow: column;
  align-items: center;
  cursor: pointer;
}

.month-content-round {
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  border: 3px solid #dddddd;
  border-radius: 50%;
  margin: 10px 0 12px;
}

.month-content-active .month-content-round {
  background-color: #004bc7;
  border: 3px solid #004bc7;
}

.month-tab-sm {
  display: flex;
  align-items: flex-end;
  margin: 20px 0;
}

.month-content-img-sm {
  width: 30px;
  height: 30px;
}

.month-content-img-sm img {
  width: 100%;
}

.month-content-sm {
  height: 50px;
  flex: 1;
  position: relative;
  overflow: hidden;
}

.month-content-hr {
  height: 2px;
  background-color: #dddddd;
  margin-top: 34px;
}

.month-content-move {
  position: absolute;
  bottom: 9px;
  left: 0;
  display: flex;
  transition: all 2s;
}

.month-content-round-sm {
  width: 10px;
  height: 10px;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 50%;
  margin-top: 4px;
}

.month-content-active .month-content-round-sm {
  background-color: #004bc7;
}

.month-content-active {
  color: #004bc7;
}

.middle-content {
  min-height: 720px;
  margin: 40px 0 50px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-16 {
  margin-bottom: 16px;
}

.middle-item {
  height: 230px;
  background-color: #f5f5f5;
  cursor: pointer;
}

.middle-item:hover {
  background-color: #004bc7;
}

.middle-item-content {
  display: flex;
}

.middle-item-time {
  width: 160px;
  height: 40px;
  color: #ffffff;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  background-color: #004bc7;
  line-height: 40px;
  padding: 10px 30px;
  display: inline-block;
}

.middle-item-time span {
  margin: 0 4px;
}

.middle-item-triangle {
  width: 0;
  height: 0;
  border-top: 60px solid #004bc7;
  border-right: 60px solid transparent;
}

.middle-item:hover .middle-item-time {
  background-color: #00a3ff;
}

.middle-item:hover .middle-item-triangle {
  border-top: 60px solid #00a3ff;
}

.middle-item-title {
  height: 72px;
  color: #333333;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  box-sizing: border-box;
  line-height: 36px;
  margin: 40px 30px 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.middle-item:hover .middle-item-title,
.middle-item:hover .middle-item-address {
  color: #ffffff;
}

.middle-item-address {
  color: #ff701c;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  text-align: right;
  box-sizing: border-box;
  line-height: 24px;
  margin: 0 30px;
}

.middle-item-sm {
  height: 250 px;
  border: 1px solid #dddddd;
  cursor: pointer;
}

.middle-item-content-sm {
  padding: 10px;
}

.middle-item-time-sm {
  color: #ffffff;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  background-color: #004bc7;
  line-height: 16px;
  padding: 3px 8px;
  display: inline-block;
}

.middle-item-title-sm {
  min-height: 44px;
  color: #333333;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 22px;
  margin: 10px 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.middle-item-address-sm {
  width: 100%;
  height: 16px;
  color: #ff701c;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  text-align: right;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
